import React from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { ADMIN_ROUTES } from "../utils/adminRoutes"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../ui/button/Button"
import { FiLogOut } from "react-icons/fi"
import { userLogout } from "../store/auth/authSlice"
import { Suspense } from "react"
import { Loader } from "../components/common/Loader"

function AdminLayout() {
  const { roleId, name } = useSelector((state) => state.auth)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const location = useLocation()

  const from = location.pathname.split("/")

  return (
    <div className="w-full bg-white flex">
      <aside className="sticky left-0 top-0 w-80 h-screen bg-goalSection flex flex-col items-start justify-center gap-4 px-12 shadow-[-14px_4px_38px_#656565]">
        {ADMIN_ROUTES.filter(
          (routes) => routes.accessLevel >= roleId && routes.isInNavbar
        ).map((routes) => (
          <NavLink
            to={routes.path}
            className={({ isActive }) =>
              [
                isActive
                  ? "text-secondary hover:text-primary"
                  : "text-primary hover:text-secondary",
                " transition-all text-3xl font-bold",
              ].join(" ")
            }
            key={routes.path}
          >
            {routes.title}
          </NavLink>
        ))}
      </aside>
      <main className="w-full p-10 pr-20">
        <div className="w-full flex justify-end items-center gap-x-4 h-[60px]">
          {(location.pathname === "/iera-administration/news" ||
            location.pathname === "/iera-administration/event") && (
            <Button
              variant="secondary"
              fill="outlined"
              extraStyles={"group px-10"}
              onClick={() =>
                navigate("/iera-administration/create-post", {
                  state: {
                    from: from[from.length - 1],
                  },
                })
              }
            >
              Add
            </Button>
          )}
          <p>
            {name}
            <span className="text-secondary">
              ({roleId === 1 ? "Admin" : roleId === 2 ? "Editor" : "Partner"})
            </span>
          </p>
          <FiLogOut
            size="25"
            className="cursor-pointer text-secondary"
            onClick={() => {
              dispatch(userLogout())
              navigate("/iera-administration-login")
            }}
          />
        </div>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  )
}

export default AdminLayout
