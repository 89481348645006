
import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./services/apiSlice"
import authSlice from "./auth/authSlice"

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		auth: authSlice
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
})