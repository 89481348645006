import React from "react";
import { AdminCard } from "../../components/admin/AdminCard";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../store/slices/userSlice";

const Users = () => {
  const { data } = useGetUsersQuery();

  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  return (
    <div className="space-y-6 mt-10 max-w-3xl h-[calc(100vh-200px)] pr-4">
      {data?.map((post) => (
        <AdminCard
          category="user"
          post={post}
          key={post.id}
          isLoading={isLoading}
          deleteCard={deleteUser}
        />
      ))}
    </div>
  );
};

export default Users