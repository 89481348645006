import { createFormData } from "../../utils/createFormData";
import { apiSlice } from "../services/apiSlice";

export const extendAdminPostSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAdminPosts: build.query({
      query: ({ role, category }) => ({
        url: `/${role}/${category}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "posts", id })),
              { type: "posts", id: "LIST" },
            ]
          : [{ type: "posts", id: "LIST" }],
    }),
    getAdminPost: build.query({
      query: ({ role, category, id }) => ({
        url: `/${role}/${category}/${id}`,
      }),
      providesTags: ["posts"],
    }),
    deletePost: build.mutation({
      query: ({ role, category, id }) => ({
        url: `/${role}/${category}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["posts"],
    }),
    editPost: build.mutation({
      query: ({ role, category, id, ...body }) => ({
        url: `/${role}/${category}/${id}`,
        method: "PUT",
        body: createFormData(body)
      }),
      invalidatesTags: ["posts"],
    }),
    createPost: build.mutation({
      query: ({ role, category, ...body }) => ({
        url: `/${role}/${category}`,
        method: "POST",
        body: createFormData(body),
      }),
      invalidatesTags: ["posts"],
    }),
  }),
});

export const {
  useGetAdminPostsQuery,
  useGetAdminPostQuery,
  useDeletePostMutation,
  useEditPostMutation,
  useCreatePostMutation,
} = extendAdminPostSlice;
