import React, { useEffect, useState } from "react"
import { Button } from "../../ui/button/Button"
import { useLoginMutation } from "../../store/slices/userSlice"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { userLogin } from "../../store/auth/authSlice"
import Cookies from "js-cookie"

function AdminLogin() {
	const [login] = useLoginMutation()

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(state => state.auth)

	const [message, setMessage] = useState("")

	const handleLogin = async e => {
		e.preventDefault()

		const username = e.target.username.value
		const password = e.target.password.value

		const userDatas = await login({ login: username, password })

		console.log(userDatas)

		if (userDatas.data) {
			dispatch(userLogin(userDatas.data))
			Cookies.set("token", userDatas.data.token)
		}
    else{
      setMessage("Invalid Login or Password")
    }

	}

	useEffect(() => {
		if (user.token)
			navigate("/iera-administration", {
				state: {
					from: "login"
				}
			})
	}, [user, navigate])

	return (
		<div className="w-full h-screen bg-white flex flex-col justify-center items-center gap-4">
			<h3 className="text-4xl text-secondary font-bold">Admin</h3>
			<form action="" className="w-full max-w-sm flex flex-col items-center gap-6" onSubmit={handleLogin}>
				<input
					type="text"
					name="username"
					className="w-full h-14 border border-primary bg-goalSection rounded-2xl px-4 text-primary"
					placeholder="Username"
				/>
				<input
					type="password"
					name="password"
					className="w-full h-14 border border-primary bg-goalSection rounded-2xl px-4 text-primary"
					placeholder="Password"
				/>

				<p className="text-sm text-red-600">{message}</p>
				<Button variant={`secondary`} extraStyles={"px-8 md:w-40 w-full"} fill={"outlined"} type={"submit"}>
					Login
				</Button>
			</form>
		</div>
	)
}

export default AdminLogin
