import React from "react"
import { useCreatePostMutation } from "../../store/slices/adminPostSlice"
import { fields } from "../../utils/fields.types"
import { useForm } from "react-hook-form"
import ReactDatePicker from "react-datepicker"
import { Input } from "../../ui/input/Input"
import { FileInput } from "../../ui/input/FileInput"
import { Controller } from "react-hook-form"
import { CustomDatePickerInput } from "../../ui/input/CustomDatePickerInput"
import { Button } from "../../ui/button/Button"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { roleIdToName } from "../../utils/roleToName"
import Spinner from "../../ui/spinner/Spinner"
import { TextEditor } from "../../components/common/TextEditor"

const CreatePost = () => {
	const navigate = useNavigate()

	const { state } = useLocation()

	const [createPost, { error, isLoading }] = useCreatePostMutation()

	const { roleId } = useSelector(state => state.auth)

	const {
		register,
		formState: { errors },
		control,
		handleSubmit
	} = useForm({
		reValidateMode: "onSubmit",
		mode: "onSubmit"
	})

	const onSubmit = async e => {
		const body = {
			...e,
			category: state?.from,
			role: roleIdToName(roleId)
		}

		if (e.date) {
			body.startDate = e.date[0]
			body.endDate = e.date[1]
		}

		console.log(body)

		await createPost(body)
		if (!error) {
			navigate(`/iera-administration/${state?.from}`)
		}
	}

	return (
		<div className="pr-4">
			<h2 className="text-3xl font-bold text-secondary">
				Create {state?.from[0].toUpperCase() + state?.from.slice(1)}
			</h2>

			<form className="my-10 max-w-xl w-full flex flex-col gap-y-6 mt-6" onSubmit={handleSubmit(onSubmit)}>
				{Object.entries(fields[state?.from]).map(([key, value]) => {
					if (key === "description")
						return (
							<Controller
								render={({ field }) => <TextEditor {...field} />}
								name="description"
								control={control}
								defaultValue=""
								key={key}
							/>
						)
					// if (value.type === "date")
					// 	return (
					// 		<div key={key}>
					// 			<Controller
					// 				name={key}
					// 				render={({ field: { onChange, value: inputValue } }) => {
					// 					return (
					// 						<ReactDatePicker
					// 							dateFormat={"dd-MM-yyyy"}
					// 							selected={inputValue}
					// 							onChange={onChange}
					// 							placeholderText={value.placeholder}
					// 							customInput={<CustomDatePickerInput />}
					// 						/>
					// 					)
					// 				}}
					// 				control={control}
					// 			/>
					// 		</div>
					// 	)
					if (key === "img")
						return <FileInput img={value.value} formKey={key} register={register} key={key} />
					return (
						<Input
							type={value.type}
							placeholder={value.placeholder}
							defaultValue={value.value}
							key={key}
							register={register}
							name={key}
							errors={errors}
							validationSchema={{
								required: !key === "img"
							}}
							extraStyles={"w-full"}
						/>
					)
				})}
				<Button variant="secondary" fill="contained" extraStyles={"w-1/3 h-[64px]"} disabled={isLoading}>
					{isLoading ? <Spinner size={1} color={1} /> : "Submit"}
				</Button>
			</form>
		</div>
	)
}

export default CreatePost
