import { RequireAuth } from "./utils/RequireAuth"
import { RequireRole } from "./utils/RequireRole"
import { lazy } from "react"
import { Route, Routes } from "react-router-dom"
import MainLayout from "./layouts/MainLayout"
import AdminLayout from "./layouts/AdminLayout"
import { NotFound } from "./pages/NotFound"
import AdminLogin from "./pages/admin/AdminLogin"
const Board = lazy(() => import("./pages/public/Board"))
const JoinUs = lazy(() => import("./pages/public/JoinUs"))
const Governance = lazy(() => import("./pages/public/Governance"))
const Secretariat = lazy(() => import("./pages/public/Secretariat"))
const ActivitiesAndProjects = lazy(() => import("./pages/public/ActivitiesAndProjects"))
const Home = lazy(() => import("./pages/public/Home"))
const WhoWeAre = lazy(() => import("./pages/public/WhoWeAre"))
const NewsAndEvents = lazy(() => import("./pages/public/NewsAndEvents"))
const ContactUs = lazy(() => import("./pages/public/ContactUs"))
const SingleNews = lazy(() => import("./pages/public/SingleNews"))
const Partner = lazy(() => import("./pages/public/Partner"))
const OurMembers = lazy(() => import("./pages/public/OurMembers"))
const CreateUser = lazy(() => import("./pages/admin/CreateUser"))
const AdminPost = lazy(() => import("./pages/admin/AdminPost"))
const Requests = lazy(() => import("./pages/admin/Requests"))
const EditPage = lazy(() => import("./pages/admin/EditPage"))
const CreatePost = lazy(() => import("./pages/admin/CreatePost"))
const Subscribers = lazy(() => import("./pages/admin/Subscribers"))
const Users = lazy(() => import("./pages/admin/Users"))

function App() {
	return (
		<Routes>
			<Route path="/" element={<MainLayout />}>
				<Route index element={<Home />} />
				<Route path="who-we-are" element={<WhoWeAre />} />
				<Route path="partners" element={<Partner />} />
				<Route path="news-and-events/:type" element={<NewsAndEvents />} />
				<Route path="contact-us" element={<ContactUs />} />
				<Route path="/news-and-events/:type/:id" element={<SingleNews />} />
				<Route path="/activities-and-projects" element={<ActivitiesAndProjects />} />
				<Route path="/governance" element={<Governance />} />
				<Route path="/secretariat" element={<Secretariat />} />
				<Route path="/join-us" element={<JoinUs />} />
				<Route path="/board-members" element={<Board />} />
				<Route path="/our-members" element={<OurMembers />} />
				<Route path="*" element={<NotFound />} />
			</Route>
			<Route element={<RequireAuth />}>
				<Route path="/iera-administration" element={<AdminLayout />}>
					<Route element={<RequireRole />}>
						<Route path="/iera-administration/:category" element={<AdminPost />} />
						<Route path="/iera-administration/users" element={<Users />} />
						<Route path="/iera-administration/create-user" element={<CreateUser />} />
						<Route path="/iera-administration/requests" element={<Requests />} />
						<Route path="/iera-administration/create-post" element={<CreatePost />} />
						<Route path="/iera-administration/subscribers" element={<Subscribers />} />
					</Route>
					<Route path="/iera-administration/edit/:category/:id" element={<EditPage />} />
				</Route>
			</Route>
			<Route path="/iera-administration-login" element={<AdminLogin />} />
		</Routes>
	)
}

export default App
