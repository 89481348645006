import React from "react";
import {
  useApproveMutation,
  useDeclineMutation,
  useGetRequestsQuery,
} from "../../store/slices/requestSlice";
import { useSelector } from "react-redux";
import { roleIdToName } from "../../utils/roleToName";
import { Button } from "../../ui/button/Button";

const Requests = () => {
  const { roleId } = useSelector((state) => state.auth);
  const { data } = useGetRequestsQuery(roleIdToName(roleId));
  const [approve] = useApproveMutation();
  const [decline] = useDeclineMutation();

  return (
    <div className="max-w-4xl space-y-10 h-[calc(100vh-200px)] pr-4">
      <h2 className="text-4xl text-secondary font-bold">Requests</h2>
      {data?.map((req) => (
        <div className="flex items-center gap-10">
          <div className="p-10 w-full rounded-2xl bg-goalSection">
            {req.title}
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="secondary"
              fill="contained"
              extraStyles={"w-[110px]"}
              onClick={() =>
                approve({ role: roleIdToName(roleId), id: req.postId })
              }
            >
              Approve
            </Button>
            <Button
              variant="primary"
              fill="contained"
              extraStyles={"w-[110px]"}
              onClick={() =>
                decline({ role: roleIdToName(roleId), id: req.postId })
              }
            >
              Decline
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};


export default Requests