import React from "react"
import { fields } from "../../utils/fields.types"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../../ui/button/Button"
import { Controller, useForm } from "react-hook-form"
import { useEditPostMutation, useGetAdminPostQuery } from "../../store/slices/adminPostSlice"
import "react-datepicker/dist/react-datepicker.css"
import { FileInput } from "../../ui/input/FileInput"
import { Input } from "../../ui/input/Input"
import { roleIdToName } from "../../utils/roleToName"
import { useSelector } from "react-redux"
import { useUpdateUserMutation } from "../../store/slices/userSlice"
import Spinner from "../../ui/spinner/Spinner"
import { dateFormat } from "../../utils/dateFormat"
import { format } from "date-fns"
import { TextEditor } from "../../components/common/TextEditor"

const EditPage = () => {
	const { category, id } = useParams()

	const {
		register,
		formState: { errors },
		handleSubmit,
		control
	} = useForm({
		mode: "onSubmit",
		reValidateMode: "onSubmit"
	})

	const { roleId } = useSelector(state => state.auth)

	const { post } = useGetAdminPostQuery(
		{ role: roleIdToName(roleId), category, id },
		{
			selectFromResult: ({ data }) => ({
				post:
					data &&
					Object.entries(fields[category])?.map(([key, value]) => [
						key,
						{
							...value,
							value: value.type === "date" ? format(dateFormat(data[key]), "yyyy-MM-dd") : data[key]
						}
					])
			})
		}
	)

	const [editPost, { isLoading }] = useEditPostMutation()
	const [updateUser] = useUpdateUserMutation()
	const navigate = useNavigate()

	const handleEdit = async e => {
		const body = {
			...e,
			category,
			id,
			role: roleIdToName(roleId)
		}

		console.log(body)
		category === "user" ? await updateUser(body) : await editPost(body)
		navigate(`/iera-administration/${category}`)
	}

	return (
		<div>
			<h2 className="text-4xl text-secondary font-bold my-10">Edit Menu</h2>
			<form className="flex flex-col gap-y-6 max-w-xl w-full" onSubmit={handleSubmit(handleEdit)}>
				{post?.map(([key, value]) => {
					if (key === "description")
						return (
							<Controller
								render={({ field }) => <TextEditor {...field} />}
								name="description"
								control={control}
								defaultValue={value.value}
								key={key}
							/>
						)

					if (key === "img")
						return <FileInput img={value.value} formKey={key} register={register} key={key} />
					return (
						<Input
							type={value.type}
							placeholder={value.placeholder}
							defaultValue={value.value}
							key={key}
							register={register}
							name={key}
							errors={errors}
							validationSchema={{
								required: true
							}}
						/>
					)
				})}
				<Button variant="secondary" fill="contained" extraStyles={"w-1/3 h-[64px]"} disabled={isLoading}>
					{isLoading ? <Spinner size={1} color={1} /> : "Submit"}
				</Button>
			</form>
		</div>
	)
}

export default EditPage
