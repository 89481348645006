import React from "react"
import { useForm } from "react-hook-form"
import { Input } from "../../ui/input/Input"
import { Button } from "../../ui/button/Button"
import { useCreateUserMutation } from "../../store/slices/userSlice"
import { FileInput } from "../../ui/input/FileInput"
import Spinner from "../../ui/spinner/Spinner"
import { useNavigate } from "react-router-dom"

const CreateUser = () => {
	const {
		register,
		formState: { errors },
		getValues,
		handleSubmit
	} = useForm({
		reValidateMode: "onSubmit",
		mode: "onSubmit"
	})

	const navigate = useNavigate()
	const [createUser, { error, isLoading }] = useCreateUserMutation()

	const onSubmit = async e => {
		const body = {
			...e,
			roleId: e.roleId === "Editor" ? 2 : 3
		}
		await createUser(body)
		if (!error) {
			navigate("/iera-administration/users")
		}
	}

	return (
		<div>
			<h2 className="text-4xl text-secondary font-bold">Create User</h2>
			<form
				action=""
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col gap-y-4 mt-6 pr-4 max-w-xl w-full"
			>
				<Input
					type="text"
					placeholder="Full Name"
					register={register}
					name="name"
					errors={errors}
					postError={error}
					validationSchema={{ required: true }}
					values={getValues()}
				/>
				<Input
					type="text"
					placeholder="Nick Name"
					register={register}
					name="login"
					errors={errors}
					postError={error}
					validationSchema={{ required: true }}
					values={getValues()}
				/>
				<Input
					type="password"
					placeholder="Password"
					register={register}
					name="password"
					errors={errors}
					postError={error}
					validationSchema={{ required: true }}
					values={getValues()}
				/>
				<Input
					type="password"
					placeholder="Repeat Password"
					register={register}
					name="repeat_password"
					errors={errors}
					validationSchema={{
						required: true,
						validate: value => (value !== getValues("password") ? "Passwords doesnt match" : true)
					}}
					values={getValues()}
				/>
				<FileInput register={register} formKey="image" />
				<select
					className="py-4 px-2 outline-0 border bg-input"
					{...register("roleId", {
						required: true
					})}
				>
					<option>Editor</option>
					<option>Partner</option>
				</select>
				<p>{error && "Something went wrong"}</p>
				<Button variant="secondary" fill="contained" extraStyles={"w-1/3 h-[64px]"} disabled={isLoading}>
					{isLoading ? <Spinner /> : "Submit"}
				</Button>
			</form>
		</div>
	)
}

export default CreateUser
