import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("token", token);
    }

    return headers;
  },
});
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["posts", "user", "subscribe"],
  endpoints: (build) => ({}),
});
