import { apiSlice } from "../services/apiSlice"

export const extendSubscribersSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSubscribers: build.query({
      query: () => ({
        url: "/admin/subscribers",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "subscribe", id })),
              { type: "subscribe", id: "LIST" },
            ]
          : [{ type: "subscribe", id: "LIST" }],
    }),
    subscribe: build.mutation({
      query: (body) => ({
        url: "/subscribe",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subscribe"],
    }),
    deleteSubscribe: build.mutation({
      query: (id) => ({
        url: `/admin/subscribers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subscribe"],
    }),
    downloadFile: build.query({
      query: () => ({
        url: "/admin/subscribers/download",
        responseHandler: async (response) =>
          window.URL.createObjectURL(await response.blob()),
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Disposition": "attachment; filename=subscribers.xlsx",
        },
      }),
      providesTags: ["subscribe"],
    }),
  }),
})

export const {
  useGetSubscribersQuery,
  useSubscribeMutation,
  useDeleteSubscribeMutation,
  useDownloadFileQuery
} = extendSubscribersSlice
