import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import React, { forwardRef, useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

export const TextEditor = forwardRef(({ onChange, value }, ref) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [updated, setUpdated] = useState(false)

	useEffect(() => {
		if (!updated) {
			const defaultValue = value ? value : ""
			const blocksFromHtml = htmlToDraft(defaultValue)
			const contentState = ContentState.createFromBlockArray(
				blocksFromHtml.contentBlocks,
				blocksFromHtml.entityMap
			)
			const newEditorState = EditorState.createWithContent(contentState)
			setEditorState(newEditorState)
		}
	}, [value, updated])

	const onEditorStateChange = editorState => {
		setUpdated(true)
		setEditorState(editorState)

		return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
	}

	return (
		<React.Fragment>
			<div className="editor">
				<Editor
					editorClassName="bg-input min-h-[350px] px-4"
					placeholder="Write a description"
					spellCheck
					editorState={editorState}
					onEditorStateChange={onEditorStateChange}
					// defaultEditorState={{
					ref={ref}
					// }}
					toolbar={{
						fontFamily: {
							options: ["SofiaPro"]
						},
					}}
				/>
			</div>
		</React.Fragment>
	)
})
