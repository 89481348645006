import React, { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Navbar } from "../components/common/Navbar"
import { Footer } from "../components/common/Footer"
import ScrollToTop from "../components/common/ScrollToTop"
import { Loader } from "../components/common/Loader"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const MainLayout = () => {
	return (
		<>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
				language="en"
				useRecaptchaNet={true}
				useEnterprise={true}
				scriptProps={{
					async: false,
					defer: false,
					appendTo: "head",
					nonce: undefined
				}}
				container={{
					element: "captcha",
					parameters: {
						badge: "bottomleft",
						theme: "light"
					}
				}}
			>
				<Navbar />
				<main className="">
					<ScrollToTop />
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</main>
				<Footer />
			</GoogleReCaptchaProvider>
		</>
	)
}

export default MainLayout
