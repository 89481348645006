import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../ui/button/Button"

export const NotFound = () => {

	const navigate = useNavigate()

	return (
		<div className="h-[60vh] w-full flex flex-col justify-center items-center gap-10">
			<h3 className="md:text-5xl text-3xl text-center">Page Not Found</h3>
			<Button
				onClick={() => navigate("/")}
				variant="secondary" 
				fill="contained" 
				extraStyles={"md:p-5 p-2"}
			>
				Go Home
			</Button>
		</div>
	)
}
