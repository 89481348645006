import React from "react"
import { MdModeEditOutline } from "react-icons/md"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { roleIdToName } from "../../utils/roleToName"
import Spinner from "../../ui/spinner/Spinner"
import { useDeletePostMutation } from "../../store/slices/adminPostSlice"
import { useDeleteUserMutation } from "../../store/slices/userSlice"

export const AdminCard = ({ category, post }) => {
	const { roleId } = useSelector(state => state.auth)
	const navigate = useNavigate()

	const [deletePost, { isLoading: isPostLoading }] = useDeletePostMutation()
	const [deleteUser, { isLoading: isUserLoading }] = useDeleteUserMutation()


	return (
		<div className="flex min-h-[60px] gap-x-4">
			<div className="rounded-2xl w-full bg-goalSection px-6 py-4">
				<p className="">{post.title || post.name}</p>
			</div>
			<button
				className="rounded-2xl bg-goalSection px-4"
				onClick={() => navigate(`/iera-administration/edit/${category}/${post.id}`)}
			>
				<MdModeEditOutline />
			</button>
			{roleId < 3 && (
				<button
					onClick={() => {
						if (category === "news" || category === "event") {
							deletePost({
								role: roleIdToName(roleId),
								category,
								id: post.id
							})
						} else {
							deleteUser({
								category,
								id: post.id
							})
						}
					}}
					className="rounded-2xl bg-goalSection flex justify-center items-center w-14"
				>
					{isPostLoading || isUserLoading ? <Spinner size={2} color={2} /> : <RiDeleteBin6Line />}
				</button>
			)}
		</div>
	)
}
