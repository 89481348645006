import { createFormData } from "../../utils/createFormData";
import { apiSlice } from "../services/apiSlice";

export const extendUserSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => "/admin/user",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "user", id })),
              { type: "user", id: "LIST" },
            ]
          : [{ type: "user", id: "LIST" }],
    }),
    getUser: build.query({
      query: (id) => `/admin/user/${id}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "user", id })),
              { type: "user" },
            ]
          : [{ type: "user" }],
    }),
    updateUser: build.mutation({
      query: (body) => ({
        url: `/admin/user/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    deleteUser: build.mutation({
      query: ({id}) => ({
        url: `/admin/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["user"],
    }),
    login: build.mutation({
      query: (body) => ({
        url: "/auth",
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: "/admin/user",
        method: "POST",
        body: createFormData(body),
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useLoginMutation,
  useCreateUserMutation,
  useDeleteUserMutation
} = extendUserSlice;
