import { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const useVerifyCaptchaToken = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [token, setToken] = useState("");
  const verifyToken = useCallback(
    async () => await executeRecaptcha("iera").then(r => setToken(r)),
    [executeRecaptcha]
  );

  return { token, verifyToken };
};
