import { apiSlice } from "../services/apiSlice";

const extendRequestSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRequests: build.query({
      query: (role) => ({
        url: `/${role}/request`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "posts", id })),
              { type: "posts", id: "LIST" },
            ]
          : [{ type: "posts", id: "LIST" }],
    }),
    approve: build.mutation({
      query: ({ role, id }) => ({
        url: `/${role}/request/approve/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["posts"],
    }),
    decline: build.mutation({
      query: ({ role, id }) => ({
        url: `/${role}/request/decline/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["posts"],
    }),
  }),
});

export const { useGetRequestsQuery, useApproveMutation, useDeclineMutation } =
  extendRequestSlice;
