import React from "react"
import {
  useGetSubscribersQuery,
  useDeleteSubscribeMutation,
  useDownloadFileQuery,
} from "../../store/slices/subscribers"
import { MdOutlineDeleteForever } from "react-icons/md"
import { BsDownload } from "react-icons/bs"

function Subscribers() {
  const { data } = useGetSubscribersQuery()
  const [deleteSubscribe] = useDeleteSubscribeMutation()

  const handleDeleteSubscribe = (id) => {
    deleteSubscribe(id)
  }

  const { data: download } = useDownloadFileQuery()

  return (
    <div className=" flex flex-col gap-10 py-4">
      <div>
        <h3 className="text-secondary text-4xl">Newsletter(subscribed)</h3>
      </div>
      <div className="flex flex-row">
        <div className="w-[400px] h-[calc(100vh-300px)] pr-4 flex flex-col gap-6">
          {data?.length ? (
            data.map((elem) => {
              return (
                <div key={elem.id}>
                  <div className=" w-full flex justify-between items-center gap-4 p-6 bg-goalSection rounded-2xl">
                    <p className=" text-sm text-secondary select-text">
                      {elem.email}{" "}
                      <span className="text-primary">
                        subscribed to newsletter
                      </span>
                    </p>
                    <div className=" w-8">
                      <MdOutlineDeleteForever
                        className="text-primary text-3xl cursor-pointer"
                        onClick={() => handleDeleteSubscribe(elem.id)}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <h1>No results</h1>
          )}
        </div>
        <div className="flex items-end justify-end w-full">
          <form>
            <a href={download} download="subscribers.xlsx">
              <BsDownload size="34" />
            </a>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Subscribers
