import { createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"

const token = Cookies.get("token") || ""

const decoded = token && jwtDecode(token)

const initialState = {
	name: decoded.name || "",
	roleId: decoded.roleId || null,
	token: token || ""
}

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		userLogin: (state, action) => {
			const { token } = action.payload
			const decode = jwtDecode(token)
			state.name = decode.name
			state.token = token
			state.roleId = decode.roleId
		},
		userLogout: state => {
			state.name = ""
			state.token = ""
			state.roleId = null
			Cookies.remove("token")
		}
	}
})

export const { userLogin, userLogout } = authSlice.actions

export default authSlice.reducer
