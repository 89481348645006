import React from "react";
import { useParams } from "react-router-dom";
import { useGetAdminPostsQuery } from "../../store/slices/adminPostSlice";
import { useSelector } from "react-redux";
import { roleIdToName } from "../../utils/roleToName";
import { AdminCard } from "../../components/admin/AdminCard";

function AdminPost() {
  const { category } = useParams();

  const { roleId } = useSelector((state) => state.auth);

  const { data } = useGetAdminPostsQuery({
    role: roleIdToName(roleId),
    category,
  });

  return (
    <div className="overflow-hidden overflow-y-scroll space-y-6 mt-10 max-w-3xl h-[calc(100vh-200px)] pr-4">
      {data?.map((post) => (
        <AdminCard category={category} post={post} key={post.id} />
      ))}
    </div>
  );
}

export default AdminPost